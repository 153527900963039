import React from "react"
import styled from "styled-components"

import {
  bodyCopy,
  headlineOne,
  headlineTwo,
  MedWrapper,
  fontSizer,
} from "../../../Utilities"
import CommunityInfo from "../About/CommunityData/CommunityCardData"

const WrapperCommunity = styled.div`
  ${MedWrapper};
`

const CommunityCardStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    margin: 5rem auto;
  }

  .card-image-section {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(30% - 2rem);
      margin-right: 2rem;
    }

    .icon {
      max-width: 10rem;
      margin: 3rem auto;
    }

    .image {
      margin: 2rem 1rem;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .card-info-section {
    width: calc(100%);
    padding-top: 1rem;

    @media (min-width: 768px) {
      width: calc(60% - 4rem);
      margin-left: 4rem;
      padding: 4rem;
    }

    h1 {
      ${headlineOne};
      ${fontSizer(2.2, 3.8, 76.8, 110, 2.6)}
      margin-top: 0;
      text-transform: uppercase;
    }

    p {
      ${headlineTwo};
      color: #707070;
      font-weight: 300;
    }

    li {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 110, 1.8)};
      margin-bottom: 0.5rem;
      font-weight: 300;
    }
  }
`

const Content = () => {
  return (
    <WrapperCommunity>
      <CommunityCardStyled className={`community-card`}>
        <div className="card-image-section">
          {/* <div className="icon">{CommunityInfo[2].svg}</div> */}
          <div className="image">{CommunityInfo[2].img}</div>
        </div>
        <div className="card-info-section">
          <div>
            <h1>{CommunityInfo[2].title}</h1>
            <p>
              It’s always an honour to be recognized for the work we do with our
              clients. The recognition isn’t why we do it, but reminds us that
              we are on the right track and need to keep going.
            </p>
          </div>
          <div>
            <ul>
              {CommunityInfo[2].points.map((point, index) => {
                return <li key={index}>{point}</li>
              })}
            </ul>
          </div>
        </div>
      </CommunityCardStyled>
    </WrapperCommunity>
  )
}

export default Content
