import React from "react"
import CommunityAwardsHero from "../../../Elements/Images/CommunityAwardsHero"

const Hero = () => {
  return (
    <div>
      <CommunityAwardsHero />
    </div>
  )
}

export default Hero
