import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/Seo/seo"
import Hero from "../../components/PageParts/AwardsRecognition/Hero"
import Content from "../../components/PageParts/AwardsRecognition/Content"
import CommunityNav from "../../components/PageParts/GivingBack/CommunityNav"

const AwardsRecognition = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Awards and Recoginition - Switchback Creative."
        description="Awards and Recoginition. It’s always an honour to be recognized for the work we do. Here is some of our awards we've received."
        location={props.location.pathname}
        pageImg={props.data.metaImage.publicURL}
      />
      <Hero />
      <Content />
      <CommunityNav location={props.location.pathname} />
    </Layout>
  )
}

export const awardsQuery = graphql`
  {
    metaImage: file(
      relativePath: { eq: "switchback-communitypages-meta.jpg" }
    ) {
      publicURL
    }
  }
`

export default AwardsRecognition
